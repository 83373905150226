.splitter-layout .layout-pane {
	overflow: hidden !important;
}

div [data-paste-element="CIPTEX-TAB"], div [data-paste-element="CIPTEX-TAB-PANEL"], div [data-paste-element="CIPTEX-TABS"] {
	display: flex;
	flex-flow: column nowrap;
	flex: 1;
}

.divider-hitbox {
	cursor: default;
	align-self: stretch;
	display: flex;
	align-items: center;
}
	
.divider {
	height: 100%;
	border: 1px solid #808080;
}

/* Twilio Paste Switch help text is indented incorrectly */
span[data-paste-element="SWITCH_HELP_TEXT_SPACER"] {
	width: 4.375rem;
}
